<template>
    <div style="background-color:#f9f9f9;">
        <myhead :param="param" />
        <section>
            <div class="bannersys">
            </div>
        </section>
        <section style="background-color: #f3f3f3">
            <div class="htable" style="height: 64px;justify-content:center;align-items:center;font-size: 16px;">
                <div class="hcell" style="padding-right:42px;border-right: 1px solid rgba(20,20,20,0.7);font-weight: bold;">
                    <span class="mgd" @click="go('rcln')">人才理念</span></div>
                <div class="hcell" style="margin-left: 42px;"><span class="mgd" @click="go('zwsq')">人才招聘</span></div>
                <div class="hcell" style="margin-left: 80px;"><span class="mgd" @click="openZwsq" >职位申请</span></div>
            </div>
        </section>
        <router-view  ></router-view>
        <section style="height: 90px;"></section>

        <putfile v-if="zwsqWindow" @close="closeZwsq" />

    </div>
</template>
<script>
    import myhead  from  '../components/myhead.vue'
    import putfile from '../components/putfile.vue'
    const param = {
        pageindex: 5,
        rows: 6,
        page: 1,
    }
    export default {
        components: {
            myhead,
            putfile,

        },
        data() {
            return {
                param,
                zwsqWindow: false,
                bannername: require('../assets/zp_banner.png'),
            }
        },
        mounted() {

        },
        methods: {
            go(url) {
                this.$router.push({ path: "/rczp/"+url, query:null });
            },
            openZwsq(){
                this.zwsqWindow=true;
            },

            closeZwsq(){
                this.zwsqWindow=false;
            },

        },
    }
</script>
<style scoped>
    .bannersys{
        background-image: url('~assets/zp_banner.png');
        height: 600px;
    }
    .mgd {
        cursor:pointer;
        padding: 0 5px;
    }
    .mgd:hover {
        background-color: rgba(203,203,203,1);
        color: rgba(24,24,24,0.9);
        /*border-radius: 5px;*/
    }
</style>
