<style lang="less" scoped>
    .fdwindow{
        background-color: #FFFFFF;
        position: fixed;
        width: 500px;
        height: 600px;
        z-index: 1000;
        left:  calc((100% - 500px) / 2);
        top: 100px;
        border: 1px solid #000000;
    }


</style>
<template>
    <div class="fdwindow"     id="fdwindow">
        <div class="htable" style="background-color: #2185d0;height: 50px;padding: 0px 10px;font-size: 16px;line-height: 50px;font-weight: bold;color: #FFFFFF;align-items: center;justify-content: space-between;">
        <div class="hcell" >职位申请</div>
            <div class="hcell"  > <span class="pointer" @click="closeWindow"> X</span></div>
        </div>
        <div style="padding: 20px 60px; text-align: left;">
         <el-form>
             <el-form-item label="应聘职位名称" prop="zwname">
                 <el-input v-model="zwname"></el-input>
             </el-form-item>
             <el-form-item label="你的姓名" prop="youname">
                 <el-input v-model="youname"></el-input>
             </el-form-item>
             <el-form-item label="联系电话" prop="youtel">
                 <el-input v-model="youtel"></el-input>
             </el-form-item>

             <el-form-item>
                 <el-upload ref="upfile"
                            style="display:inline"
                            :auto-upload ="false"
                            :on-change="handleChange"
                            :file-list="fileList"
                            action="#">

                     请上传你的简历<br/>
                     <el-button type="sucdess">选择文件</el-button>
                 </el-upload>
             </el-form-item>
             <el-form-item>
                 <el-button type="success" @click="upload">点击上传</el-button>
             </el-form-item>
         </el-form>
        </div>
    </div>
</template>
<script>
    import { uploadFile } from "../hts/api.js"
    export default {
        name:"UploadUi",
        data(){
            return{
                zwname: '',
                youname: '',
                youtel: '',
                fileList: [],
            }
        },
        methods:{
            handleChange(file,fileList){
                this.fileList=fileList;
            },
            upload(){
                let fd=new FormData();
                fd.append("zwname",this.zwname);
                fd.append("youname",this.youname);
                fd.append("youtel",this.youtel);
                this.fileList.forEach(item=>{
                    fd.append("files",item.raw);
                    console.log(item.raw)
                })

                uploadFile(fd).then(resData => {
                    alert(resData.message)
                    this.closeWindow()
                }).catch(err => {
                    alert(err.message)
                })
            },
            closeWindow(){
                this.$emit('close')
            }
        },
        created(){

        },
        beforeDestroy(){

        },
    }
</script>

